import React, { PropsWithChildren} from 'react';
import styled from 'styled-components';

const FormGroupContainer = styled.div`
  position: relative;
  margin-bottom: ${(props) => props.theme.formContainer.spacing};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  > option, > input, > select {
    width: 100%;
  }
`

const FormGroupLabel = styled.label`
  display: block;
  margin-top: ${(props) => props.theme.formContainer.spacing};
  margin-bottom: ${(props) => props.theme.formContainer.spacing};
  margin-right: auto; // TODO make alignment a them token
  margin-left: 0;
  /* NOTE Shortcut doesn't work for some reason */
  
  font-size: ${(props) => props.theme.fontSizes.large};
`

function FormGroup(props: PropsWithChildren<{ label: string, elementName: string }>) {
  const { label, elementName, children } = props;

  return (
    <FormGroupContainer>
        <FormGroupLabel htmlFor={elementName}>{label}</FormGroupLabel>
        {children}
    </FormGroupContainer>
  );
}

export default FormGroup;
