import stringToNumber from './stringToNumber';

// The columns index for each input type in the spreadsheet
export const columns: Columns = {
  Vertical: 0,
  Revenue: 1,
  UPT: 2,
  AOV: 3,
  CR: 4,
  CurrentSolution: 5,
  ProductsInCatalog: 6,
};

// Where the incremental values start in the spreadsheet
export const inc_high_row: number = 96;
export const inc_mid_row: number = 97;
export const inc_low_row: number = 98;

// Where the values start in the spreadsheet for each input type
const opt_values_start_row: number = 47;

// Grab the values for each input type from the spreadsheet
// Note: Min, Max, and Step are ordered in the spreadsheet column for sliders
function getColumnValuesTillEmpty(configData: CalcData, column: Column) {
  const values = [];
  for (let i = opt_values_start_row; i < configData.data.length; i++) {
    const value = configData.data[i][columns[column]];
    if (value === '') break;
    values.push(value);
  }
  return values;
}

const fieldValues = (configData: CalcData) : FieldConfig => {

  const verticalValues = getColumnValuesTillEmpty(configData, 'Vertical');
  const solutionValues = getColumnValuesTillEmpty(configData, 'CurrentSolution');
  const revenueValues = getColumnValuesTillEmpty(configData, 'Revenue');
  const uptValues = getColumnValuesTillEmpty(configData, 'UPT');
  const aovValues = getColumnValuesTillEmpty(configData, 'AOV');
  const crValues = getColumnValuesTillEmpty(configData, 'CR');
  const productsValues = getColumnValuesTillEmpty(configData, 'ProductsInCatalog');

  return {
    // Dropdowns
    vertical: {
      label: 'Industry',
      values: verticalValues,
    },
    solution: {
      label: 'Current Complete-the-Look Solution',
      values: solutionValues,
    },
    // Sliders
    revenue: {
      label: 'Annual Revenue',
      step: stringToNumber(revenueValues[2]),
      min: stringToNumber(revenueValues[0]),
      max: stringToNumber(revenueValues[1]),
      format: (value) => `$${value / 1000000}M`,
    },
    upt: {
      label: 'Units Per Transaction',
      step: stringToNumber(uptValues[2]),
      min: stringToNumber(uptValues[0]),
      max: stringToNumber(uptValues[1]),
      format: (value) => `${value}`,
    },
    aov: {
      label: 'Average Order Value',
      step: stringToNumber(aovValues[2]),
      min: stringToNumber(aovValues[0]),
      max: stringToNumber(aovValues[1]),
      format: (value) => `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`
    },
    cr: {
      label: 'Conversion Rate',
      step: stringToNumber(crValues[2]),
      min: stringToNumber(crValues[0]),
      max: stringToNumber(crValues[1]),
      format: (value) =>
        // always have one decimal place
        `${value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%`
    },
    products: {
      label: 'Products',
      step: stringToNumber(productsValues[2]),
      min: stringToNumber(productsValues[0]),
      max: stringToNumber(productsValues[1]),
      format: (value) =>
        value.toLocaleString(undefined, { maximumFractionDigits: 0 })
    },
  }
};

export default fieldValues;