import {columns, inc_high_row, inc_mid_row, inc_low_row} from './fieldValues';
import stringToNumber from './stringToNumber';

export default function calculate(props: CalculateProps) {
  const { vertical, upt, aov, cr, solution, products, configData } = props;

  let iv: number = 0.0; // incremental value
  let cs_high_val: Solution = "None"; // current solution high value
  let cs_low_val: Solution = "3rd Party"; // current solution low value
  let low_row: number; // low row for const columns
  let high_row: number; // high row for const columns

  function calcRevenue() {
    iv = getBaseValue()
    iv += getRangeValue('UPT', upt)  // upt value
    iv += getRangeValue('AOV', aov)  // aov value
    iv += getRangeValue('CR', cr)  // cr value
    iv += getMatchValue('CurrentSolution', solution)  // solution value
    iv += getRangeValue('ProductsInCatalog', products, true)  // products value
  }

  // Return the value of the base element.
  // This is the first element in the list.
  function getBaseValue() {
    let result = 0.0
    if (!configData?.data) return result;
    switch (vertical) {
      case "Apparel":
        result = stringToNumber(configData.data[102][1]);
        low_row = 72;
        high_row = 71;
        break;
      case "Beauty":
        result = stringToNumber(configData.data[103][1]);
        low_row = 77;
        high_row = 76;
        break;
      case "Home":
        result = stringToNumber(configData.data[104][1]);
        low_row = 82;
        high_row = 81;
        break;
      case "Jewelry":
        result = stringToNumber(configData.data[105][1]);
        low_row = 87;
        high_row = 86;
        break;
      default:
        result = stringToNumber(configData.data[106][1]);
        low_row = 92;
        high_row = 91;
    }

    return result
  }

  // Returns the value from the range that is closest to the
  // given value.
  // If invert is true, the array is traversed backwards.
  function getRangeValue(column: Column, val: number, invert?: boolean) {
    let result = 0.0
    if (!configData?.data) return result;

    const low = stringToNumber(configData.data[low_row][columns[column]]);
    const high = stringToNumber(configData.data[high_row][columns[column]]);

    if (!invert) {
      // Normal ranges
      if (val >= low) {
        result = stringToNumber(configData.data[inc_low_row][columns[column]]);
      } else if (val <= high) {
        result = stringToNumber(configData.data[inc_high_row][columns[column]]);
      } else {
        result = stringToNumber(configData.data[inc_mid_row][columns[column]]);
      }
    } else {
      // Inverted ranges
      if (val <= low) {
        result = stringToNumber(configData.data[inc_low_row][columns[column]]);
      } else if (val >= high) {
        result = stringToNumber(configData.data[inc_high_row][columns[column]]);
      } else {
        result = stringToNumber(configData.data[inc_mid_row][columns[column]]);
      }
    }

    return result
  }

  // Returns the value of the cell in the specified column
  // that matches the specified value. If the value is not found,
  // the function returns 0.
  function getMatchValue(column: Column, val: string) {
    let result = 0.0
    if (!configData?.data) return result;

    switch (val) {
      case cs_high_val:
        result = stringToNumber(configData.data[inc_high_row][columns[column]]);
        break;
      case cs_low_val:
        result = stringToNumber(configData.data[inc_low_row][columns[column]]);
        break;
      default:
        result = stringToNumber(configData.data[inc_mid_row][columns[column]]);
    }

    return result
  }

  calcRevenue();

  return iv;
}