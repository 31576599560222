import styled from 'styled-components';
import theme from '../Theme';
interface TooltipProps {
  offset: number;
}

const TooltipText = styled.div<TooltipProps>`
  /* min-width: 80px; */
  background-color: ${theme.colors.tertiary};
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 30%;
  left: ${(props) => props.offset}%;
  padding: 5px 10px;
  transform: translateX(-50%);

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent;
    border-top-color: ${theme.colors.tertiary};
  }
`;

const Tooltip = ({ children, text, offset }: { children?: React.ReactNode; text: string | number; offset: number }) => {
  return (
    <>
      <TooltipText offset={offset || 0}>{text}</TooltipText>
      {children}
    </>
  );
};

export default Tooltip;
