import { DefaultTheme } from 'styled-components';

export const tokenPrefix = '--fm-roi';

const theme: DefaultTheme = {
  containerWidth: '100%',
  containerMaxWidth: '1400px',
  colors: {
    primary: `var(${tokenPrefix}-color-primary, #222222);`,
    secondary: `var(${tokenPrefix}-color-secondary, #D9D9D9);`,
    tertiary: `var(${tokenPrefix}-color-tertiary, #64DCE0);`,
  },
  fontFamily: `var(${tokenPrefix}-font-family, Circular, sans-serif);`,
  fontSizes: {
    button: `var(${tokenPrefix}-font-size-button, 13px);`,
    small: `var(${tokenPrefix}-font-size-small, 11px);`,
    medium: `var(${tokenPrefix}-font-size-medium, 13px);`,
    large: `var(${tokenPrefix}-font-size-large, 18px);`,
    xlarge: `var(${tokenPrefix}-font-size-xlarge, 32px);`,
  },
  formContainer: {
    spacing: `var(${tokenPrefix}-field-spacing, 1em);`,
  },
  button: {
    borderRadius: `var(${tokenPrefix}-button-border-radius, 32px 32px 32px 32px);`,
    textColor: `var(${tokenPrefix}-button-text-color, #ffffff);`,
    padding: `var(${tokenPrefix}-button-padding, 10px 25px 10px 25px);`,
  }
};

export default theme;