import React, { useCallback, useEffect, useRef, useState } from 'react';
import Slider from 'react-input-slider';
import styled from 'styled-components';
import FormGroup from './components/FormGroup';
import Tooltip from './components/Tooltip';
import calculate from './util/calculate';
import fetchCSV from './util/fetchCSV';
import getFieldValues from './util/fieldValues';
import theme from './Theme';

const CalcContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: calc((${(props) => props.theme.formContainer.spacing}) * 3);

  * {
    box-sizing: border-box;
  }

  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.colors.primary};
`

const CalcForm = styled.form`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
`

const CalcSelect = styled.select`
  /* appearance: none; */
  all: unset;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 0.5rem;

  :focus-visible {
    outline-offset: 0.25rem;
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  /* customize arrow */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='%23${props => props.theme.colors.primary.replace('#', '')}' d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 0.5rem top 50%;
  background-size: 1.5rem;
  padding-right: 2.5rem;
`

const sliderStyle = {
  track: {
    width: '100%',
    marginTop: '1.5rem',
  },
  active: {
    backgroundColor: theme.colors.tertiary,
  },
}

const SliderMinMaxValues = styled.div`
  display:flex;
  justify-content:space-between;
  min-width:100%;
  font-size:0.75rem;

  > p {
    font-size: 0.825rem;
  }
`

const CalcPayoff = styled.div`
  text-align: center;
`

const CalcPayoffRev = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontSizes.xlarge};
`

const CalcButton = styled.button`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSizes.button};
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.4em;
  letter-spacing: 0.75px;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.button.textColor};
  background-color: ${(props) => props.theme.colors.tertiary};
  border-radius: ${(props) => props.theme.button.borderRadius};
  padding: ${(props) => props.theme.button.padding};
`

// @TODO: Pull in intitial values from google sheet.
const stateDefault: FormState = {
  revenue: 10000000,
  vertical: 'Apparel',
  upt: 1,
  aov: 50,
  cr: 0.3,
  solution: 'None',
  products: 100,
  calculatedRevenue: 0,
};

function App() {


  const [state, _setState] = useState<FormState>(stateDefault);
  const isFetching = useRef<Boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const setState = useCallback((newState: Partial<FormState>) => {
    _setState({ ...state, ...newState });
  }, [state, _setState]);

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState({[e.target.id]: e.target.value as Vertical});
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const iv: number = calculate(state);
    const revenue: number = state.revenue * (iv / 100);
    const formData = new FormData(e.target as HTMLFormElement);
    // dispatch custom event
    formRef.current?.dispatchEvent(new CustomEvent<CustomEventDetail>('roiCalculated', {
      detail: {
        iv,
        revenue,
        input: Object.fromEntries(formData),
      },
      bubbles: true,
    }));
    setState({ calculatedRevenue: revenue });
    e.preventDefault();
  };

  // const handleReset = () => {
  //   formRef.current?.dispatchEvent(new CustomEvent<CustomEventDetail>('roiReset', {bubbles: true}));
  // };

  const fetchConfigData = useCallback(async () => {
    let data = JSON.parse(sessionStorage.getItem("calcData") || "{}");
    data = data.data ? data : await fetchCSV();
    sessionStorage.setItem("calcData", JSON.stringify(data));
    setState({ configData: data, fieldValues: getFieldValues(data) });
    isFetching.current = false;
  }, [setState])

  // fetch config data on mount
  useEffect(() => {
    if (!isFetching.current && !state.configData) {
      isFetching.current = true;
      fetchConfigData().catch(console.error);
    }
  }, [fetchConfigData, state, isFetching]);

  // reset state on reset event
  useEffect(() => {
    if (formRef.current) {
      formRef.current.addEventListener('roiReset', () => {
        setState(stateDefault);
      })
    }
  }, [formRef, setState]);

  function calculateTooltipOffset(value: number, min: number, max: number) {
    const range = max - min;
    let percent = (value - min) / range;
    percent = Math.round(percent * 100) / 100;
    const offset = percent * 100;
    return offset;
  }

  return (
    !state.configData || !state.fieldValues ? <>
      <div>loading...</div>
    </> : <>
      <CalcContainer>
        <CalcForm onSubmit={handleSubmit} ref={formRef} data-testid="fmf">
          {state.calculatedRevenue === 0 ? <>
            {/* The purpose of this code is to add a label to the form group that is rendered
            by the <FormGroup> react component. The elementName variable is used to
            determine which field the label is associated with, and is used to generate
            the id and htmlFor attributes of the label element.*/}
            <FormGroup  label="Industry" elementName='vertical'>
              {/* This code is a select element that maps the vertical field values to the options.
              The onSelectChange function is called when the value of the select element changes. */}
              <CalcSelect name="vertical" id="vertical" onChange={onSelectChange} value={state.vertical}>
                {state.fieldValues.vertical.values.map(
                  (v: FieldDetails['values'], index: number) => <option key={index} value={v}>{v}</option>
                )}
              </CalcSelect>
            </FormGroup>

            <FormGroup  label="Current Complete-the-Look Solution" elementName='solution'>
              <CalcSelect name="solution" id="solution" onChange={onSelectChange} value={state.solution}>
                {state.fieldValues.solution.values.map(
                  (v: FieldDetails['values'], index: number) => <option key={index} value={v}>{v}</option>
                )}
              </CalcSelect>
            </FormGroup>

            <FormGroup  label="Annual Ecommerce Revenue" elementName='revenue'>
              <input type="hidden" name="revenue" id="revenue" value={state.revenue} />
              <Tooltip text={state.fieldValues.revenue.format(state.revenue)} offset={calculateTooltipOffset(state.revenue, state.fieldValues.revenue.min, state.fieldValues.revenue.max)}/>

              {/* This is a slider component
              The slider component is used to update the state value
              The values are used to calculate the incremental value and calculated revenue */}
              <Slider
                styles={sliderStyle}
                axis="x"
                xstep={state.fieldValues.revenue.step}
                xmin={state.fieldValues.revenue.min}
                xmax={state.fieldValues.revenue.max}
                x={state.revenue}
                onChange={({ x }) => setState({ revenue: x })}
              />
              <SliderMinMaxValues>
                <p>{state.fieldValues.revenue.format(state.fieldValues.revenue.min)}</p>
                <p>{state.fieldValues.revenue.format(state.fieldValues.revenue.max)}+</p>
              </SliderMinMaxValues>
            </FormGroup>

            <FormGroup  label="Number of Products in Catalog" elementName='products'>
              <input type="hidden" name="products" id="products" value={state.products} />
              <Tooltip text={state.fieldValues.products.format(state.products)} offset={calculateTooltipOffset(state.products, state.fieldValues.products.min, state.fieldValues.products.max)}/>
              <Slider
                styles={sliderStyle}
                axis="x"
                xstep={state.fieldValues.products.step}
                xmin={state.fieldValues.products.min}
                xmax={state.fieldValues.products.max}
                x={state.products}
                onChange={({ x }) => setState({ products: x })}
              />
              <SliderMinMaxValues>
                <p>{state.fieldValues.products.format(state.fieldValues.products.min)}</p>
                <p>{state.fieldValues.products.format(state.fieldValues.products.max)}+</p>
              </SliderMinMaxValues>
            </FormGroup>

            <FormGroup  label="Conversion Rate" elementName='cr'>
              <input type="hidden" name="cr" id="cr" value={state.cr} />
              <Tooltip text={state.fieldValues.cr.format(state.cr)} offset={calculateTooltipOffset(state.cr, state.fieldValues.cr.min, state.fieldValues.cr.max)}/>
              <Slider
                styles={sliderStyle}
                axis="x"
                xstep={state.fieldValues.cr.step}
                xmin={state.fieldValues.cr.min}
                xmax={state.fieldValues.cr.max}
                x={state.cr}
                onChange={({ x }) => setState({ cr: x })}
              />
              <SliderMinMaxValues>
                <p>&lt;{state.fieldValues.cr.format(state.fieldValues.cr.min)}</p>
                <p>{state.fieldValues.cr.format(state.fieldValues.cr.max)}+</p>
              </SliderMinMaxValues>
            </FormGroup>

            <FormGroup  label="Average Order Value" elementName='aov'>
              <input type="hidden" name="aov" id="aov" value={state.aov} />
              <Tooltip text={state.fieldValues.aov.format(state.aov)} offset={calculateTooltipOffset(state.aov, state.fieldValues.aov.min, state.fieldValues.aov.max)}/>
              <Slider
                styles={sliderStyle}
                axis="x"
                xstep={state.fieldValues.aov.step}
                xmin={state.fieldValues.aov.min}
                xmax={state.fieldValues.aov.max}
                x={state.aov}
                onChange={({ x }) => setState({ aov: x })}
              />
              <SliderMinMaxValues>
                <p>{state.fieldValues.aov.format(state.fieldValues.aov.min)}</p>
                <p>{state.fieldValues.aov.format(state.fieldValues.aov.max)}+</p>
              </SliderMinMaxValues>
            </FormGroup>

            <FormGroup  label="Units Per Transaction" elementName='upt'>
              <input type="hidden" name="upt" id="upt" value={state.upt} />
              <Tooltip text={state.upt} offset={calculateTooltipOffset(state.upt, state.fieldValues.upt.min, state.fieldValues.upt.max)}/>
              <Slider
                styles={sliderStyle}
                axis="x"
                xstep={state.fieldValues.upt.step}
                xmin={state.fieldValues.upt.min}
                xmax={state.fieldValues.upt.max}
                x={state.upt}
                onChange={({ x }) => setState({ upt: x })}
              />
              <SliderMinMaxValues>
                <p>{state.fieldValues.upt.format(state.fieldValues.upt.min)}</p>
                <p>{state.fieldValues.upt.format(state.fieldValues.upt.max)}+</p>
              </SliderMinMaxValues>
            </FormGroup>

            <div>
              <CalcButton type="submit" name='calculate'>Calculate</CalcButton>
            </div>
          </> : <>
            <CalcPayoff data-testid="fmcp">
              <p>Based on the information you provided, with FindMine, you could be driving</p>

              <p><CalcPayoffRev>${state.calculatedRevenue.toLocaleString()}</CalcPayoffRev> in incremental revenue</p>

              <p>All while increasing your Customer LIfetime Value by boosting AOV, UPT, and Conversion Rate</p>
              <p>Talk to our team to learn more about how dynamic shoppable content could enhance your business.</p>
            </CalcPayoff>
            {/* <CalcButton type="button" onClick={handleReset}>Reset</CalcButton> */}
          </>}
        </CalcForm>
      </CalcContainer>
    </>
  );
}

export default App;
