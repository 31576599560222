import Papa from 'papaparse';

const fetchCSV = async () => {
    let data = {};
    const target = `https://docs.google.com/spreadsheets/d/e/2PACX-1vSoYLy-LOxrPxpEi493iRJufcja3tqr-JvSPvdJxudU2r11XOxN9JRLemZma-dA1N7az6nzEUsaniKS/pub?gid=1294478183&single=true&output=csv`; //file

    const res = await fetch(target, {
        method: 'get',
        headers: {
            'content-type': 'text/csv;charset=UTF-8',
        }
    });

    if (res.status === 200) {
        const csvString: string = await res.text();
        data = Papa.parse(csvString);
    } else {
        console.log(`Error code ${res.status}`);
    }

    return data;
}
export default fetchCSV;